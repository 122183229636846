<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

export default {
	name: "BoQuiz",
	extends: Base,
  data(){
    return {
		Name:"BoQuiz",
		ansA:0,
		ansB:0,
		ansC:0,
		ansD:0,
		quest:0,
		resume:0,
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
	this.refreshData()
	},
  watch:{
    '$route.query'(){
		this.refreshData()
	},
    'filter.level'(v){
		if(!v) return
		this.search()
	},
	'filter.search'(v){
        this.search()
    },
    'row.aq_question'(v){
		this.quest =  v.length
    },
    'row.aq_resume'(v){
    	this.resume =  v.length
    },
    'row.aq_a'(v){
    	this.ansA =  v.length
    },
    'row.aq_b'(v){
    	this.ansB =  v.length
    },
    'row.aq_c'(v){
    	this.ansC =  v.length
    },
    'row.aq_d'(v){
    	this.ansD =  v.length
    },
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-9 col-lg-3 col-sm-4 ml-auto">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-block btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="aq_question"></SortField>
									</th>
									<th>Create Date</th>
									<th>{{fields.aq_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.aq_question}}</td>
									<td>{{(v.aq_create_at).dates('format2')}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
											@click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" Quiz"}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-6">
							<BoField class="mb-0" name="aq_question" mandatory>
								<textarea name="aq_question" rows="5" v-model="row.aq_question" v-bind="validation('aq_question')"
									class="form-control"></textarea>
							</BoField>
							<small class="mb-2 d-inline-block text-info">{{validation('aq_question').maxlength-quest}} character remaining </small>   
							<BoField name="aq_link" :attr="{type:'link'}" v-model="row.aq_link"></BoField>
							<BoField name="aq_is_active">
								<radio name="aq_is_active" v-model="row.aq_is_active" option="Y"
									:attr="validation('aq_is_active')">Active</radio>
								<radio name="aq_is_active" v-model="row.aq_is_active" option="N">Inactive</radio>
							</BoField>
						</div>
						<div class="col-md-6">

							<BoField name="aq_key">
								<radio name="aq_key" v-model="row.aq_key" option="A" :attr="validation('aq_key')">A
								</radio>
								<radio name="aq_key" v-model="row.aq_key" option="B">B</radio>
								<radio name="aq_key" v-model="row.aq_key" option="C">C</radio>
								<radio name="aq_key" v-model="row.aq_key" option="D">D</radio>
							</BoField>

							<BoField class="mb-0" name="aq_resume" mandatory>
								<textarea name="aq_resume" rows="2" v-model="row.aq_resume" v-bind="validation('aq_resume')"
									class="form-control"></textarea>
							</BoField>
							<small class="mb-2 d-inline-block text-info">{{validation('aq_resume').maxlength-resume}} character remaining </small> 
							<div class="row">
								<div class="col-md-6">
									<BoField class="mb-0" name="aq_a" mandatory v-model="row.aq_a" v-bind="validation('aq_a')"></BoField>
									<small class="mb-2 d-inline-block text-info">{{validation('aq_a').maxlength-ansA}} character remaining </small> 
								</div>
								<div class="col-md-6">
									<BoField class="mb-0" name="aq_b" mandatory v-model="row.aq_b" v-bind="validation('aq_b')"></BoField>
									<small class="mb-2 d-inline-block text-info">{{validation('aq_b').maxlength-ansB}} character remaining </small>
								</div>
								<div class="col-md-6">
									<BoField class="mb-0" name="aq_c" mandatory v-model="row.aq_c" v-bind="validation('aq_c')"></BoField>
									<small class="mb-2 d-inline-block text-info">{{validation('aq_c').maxlength-ansC}} character remaining </small>
								</div>
								<div class="col-md-6">
									<BoField class="mb-0" name="aq_d" mandatory v-model="row.aq_d" v-bind="validation('aq_d')"></BoField>
									<small class="mb-2 d-inline-block text-info">{{validation('aq_d').maxlength-ansD}} character remaining </small>
								</div>
							</div>
						</div>
					</div>
					<div class="text-right">
						<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{($route.params.id=='add' ? 'Add':'Update')+" Quiz"}} <i
								class="icon-arrow-right14 position-right"></i></button>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>